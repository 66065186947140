import React from "react";
import { useDispatch } from "react-redux";
import { Col, Form, Input, Row, Slider } from "antd";
import { QTypeProps } from "./QTypeProps";
import {
  AnswerType,
  FormatAnswerType,
  QuestionOptionsScaleParameter,
} from "../../store/questions/types";
import { updateQuestion } from "../../store/questions/actions";
import { QuestionBody, QuestionTitle, QuestionWrapper } from "./questions.styled";

const DistributionType: React.FC<QTypeProps> = ({ question }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { parameters } = question;
  const options: any = parameters.options || [];

  /**
   * Функция сохранения ответа
   * @param values все значения формы
   */
  const handleAnswer = (values: any) => {
    const assertions: AnswerType[] = Object.values(values);
    // Получаем максимальное количество баллов
    const max = parameters.score || 100;
    // Получаем сумму баллов всех полей
    const sum = assertions.reduce(
      (sum: number, value: any) => (value.score ? sum + value.score : sum),
      0
    );
    let formatAnswers: FormatAnswerType = {};
    for (const item of assertions) {
      formatAnswers[item.key] = item.score;
    }
    dispatch(
      updateQuestion({
        ...question,
        answer: sum === max ? formatAnswers : undefined,
      })
    );
  };

  /**
   * Функция распределения баллов по слайдерам
   * @param _ Текущее значение
   * @param values Все значения
   */
  const distributeScore = (changedValue: any, values: any) => {
    // Получаем индекс измененного поля
    const field: string = Object.keys(changedValue).toString();
    // Получаем максимальное количество баллов
    const max = parameters.score || 100;
    // Получаем сумму баллов всех полей
    const sum = Object.values(values).reduce(
      (sum: number, value: any) => (value.score ? sum + value.score : sum),
      0
    );
    // Получаем оставшееся количество баллов
    const balance = max - sum;
    // Если оставшихся баллов больше нет, не даем изменять значение поля
    if (balance <= 0) {
      form.setFields([
        {
          name: [field, "score"],
          value: changedValue[field]["score"] + balance,
        },
      ]);
    }
  };

  /**
   * Восстанавливаем значение слайдера если оно было установлено
   * @param key Ключ ответа
   */
  const sliderInitialValue = (key: string): number => {
    return question.answer && typeof question.answer === "object" ? question.answer[key] : 0;
  };

  return (
    <QuestionWrapper>
      <QuestionTitle>{question.text}</QuestionTitle>
      <QuestionBody>
        <Form form={form} onFinish={handleAnswer} onValuesChange={distributeScore}>
          {options.map((item: QuestionOptionsScaleParameter, key: number) => (
            <Row gutter={[16, 16]} key={key}>
              <Col xs={24} sm={18} md={16} lg={14}>
                <Form.Item name={[key, "key"]} initialValue={item.key} hidden>
                  <Input type="hidden" />
                </Form.Item>
                <Form.Item name={[key, "text"]} initialValue={item.text}>
                  <Input.TextArea autoSize readOnly bordered={false} style={{ resize: "none" }} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={6} md={8} lg={10}>
                <Form.Item name={[key, "score"]} initialValue={sliderInitialValue(item.key)}>
                  <Slider
                    tooltipVisible
                    min={0}
                    max={parameters.score}
                    style={{ width: "100%" }}
                    onAfterChange={() => form.submit()}
                  />
                </Form.Item>
              </Col>
            </Row>
          ))}
        </Form>
      </QuestionBody>
    </QuestionWrapper>
  );
};

export default DistributionType;
