import {
  SURVEY_ADD,
  SURVEY_DELETE,
  SURVEY_SET,
  SURVEY_UPDATE,
  SurveyActionTypes,
  ISurvey,
} from "./types";

const initialState = [] as ISurvey[];

export function surveysReducer(state = initialState, action: SurveyActionTypes): ISurvey[] {
  switch (action.type) {
    case SURVEY_ADD:
      return [...state, { ...action.payload }];
    case SURVEY_DELETE:
      return state.filter((item) => item.id !== action.payload);
    case SURVEY_UPDATE:
      return state.map((item) => (item.id === action.payload.id ? action.payload : item));
    case SURVEY_SET:
      return action.payload;
    default:
      return state;
  }
}
