import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Button, Col, message, Result, Row, Space, TreeSelect, Typography } from "antd";
import { useTranslation } from "react-i18next";

import { RootState } from "../store";
import {
  checkUuid,
  setFilledSurveyId,
  setDepartmentId,
  setLoading,
  loadSurveyData,
  setRedirect,
} from "../store/app/actions";

import { asyncCreateFilledSurvey } from "../services/surveys.services";
import { treeRecursive } from "../helpers/treeRecursive";

import { ReactComponent as FlagIcon } from "../assets/lamp.svg";

const Start = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const departments = useSelector((state: RootState) => state.departments);
  const { uuid, department_id, filled_survey_id, redirect } = useSelector(
    (state: RootState) => state.app
  );

  /**
   * Проверяем корректность uuid при инициализации
   */
  useEffect(() => {
    // Подгружаем company_id, и список департаментов
    if (uuid) {
      dispatch(checkUuid(uuid));
    }
  }, [dispatch, uuid]);

  /**
   * Проверяем если есть кодовое слово, сразу перекидываем в опрос
   */
  useEffect(() => {
    if (uuid && filled_survey_id) {
      dispatch(loadSurveyData(uuid));
    }
  }, [uuid, filled_survey_id, dispatch]);

  // Если нужно переместиться на другую страницу
  useEffect(() => {
    redirect && history.push(redirect);
    return () => {
      dispatch(setRedirect());
    };
  }, [dispatch, history, redirect]);

  // Если UUID нет ни в localStorage ни в url, показываем ошибку
  if (!uuid)
    return (
      <Result
        status="404"
        title={t("code.incorrect_uuid.title")}
        subTitle={t("code.incorrect_uuid.subtitle")}
      />
    );

  /**
   * Соглашаемся получить кодовое слово
   */
  const handleAgree = async () => {
    if (!department_id) return;
    dispatch(setLoading(true));
    try {
      const filledSurvey = await asyncCreateFilledSurvey(uuid, department_id);
      if (filledSurvey) {
        // Устанавливаем код
        dispatch(setFilledSurveyId(filledSurvey.id));
        // Сохраняем в браузер
        localStorage.setItem("filled_survey_id", filledSurvey.id);
        localStorage.setItem("started_time", new Date().toString());
        // Загружаем вопросы
        //dispatch(loadQuestions(uuid));
        // Переходим в опрос
        history.push("/step/0");
      } else {
        message.error(t("app.wrong"));
      }
    } catch (error: any) {
      if (error.response) {
        const { data } = error.response;
        message.error(data.result);
      }
    } finally {
      // Убираем загрузчик
      dispatch(setLoading(false));
    }
  };

  /**
   * Изменяем выбранный департамент
   * @param id департамента
   */
  const handleChangeDepartmentId = (id: number) => {
    if (id) {
      dispatch(setDepartmentId(id));
      localStorage.setItem("department_id", id.toString());
    } else {
      dispatch(setDepartmentId(undefined));
      localStorage.removeItem("department_id");
    }
  };

  return (
    <Row className="login">
      <Col xs={{ span: 24 }} md={{ span: 20, offset: 2 }} lg={{ span: 16, offset: 4 }}>
        <Row className="login__card">
          <Col xs={{ span: 24 }} sm={{ span: 11 }} className="login__card-about">
            {/* <img width="77" src={unti} alt="Университет 2035" /> */}
          </Col>
          <Col xs={{ span: 22, offset: 1 }} sm={{ span: 11 }} className="login__card-auth">
            <Typography.Title level={3} className="login__card-title">
              Вход
            </Typography.Title>
            <Alert
              message=""
              description="Чтобы принять участие в исследовании необходимо выбрать ваш департамент нажать кнопку &laquo;Войти&raquo;"
              type="warning"
              icon={<FlagIcon />}
              showIcon
            />
            <Space style={{ width: "100%" }} direction="vertical">
              {departments && (
                <TreeSelect
                  style={{ width: "100%" }}
                  showSearch
                  value={department_id}
                  dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                  placeholder={t("templates.choose-department")}
                  allowClear
                  treeDefaultExpandAll
                  onChange={handleChangeDepartmentId}
                  treeData={treeRecursive(departments)}
                />
              )}
              <Button
                onClick={handleAgree}
                type="primary"
                size="large"
                block
                disabled={!department_id}
              >
                Войти
              </Button>
            </Space>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Start;
