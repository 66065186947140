import React from "react";
import { useDispatch } from "react-redux";
import { Checkbox, Input, Space } from "antd";

import { QTypeProps } from "./QTypeProps";
import { QuestionOptionsRadioParameter } from "../../store/questions/types";
import { updateQuestion } from "../../store/questions/actions";
import { CheckboxChangeEvent } from "antd/lib/checkbox/Checkbox";
import { useTranslation } from "react-i18next";
import { QuestionBody, QuestionTitle, QuestionWrapper } from "./questions.styled";

const MultipleType: React.FC<QTypeProps> = ({ question }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const options: any[] = question.parameters?.options || [];

  const handleChanges = (e: CheckboxChangeEvent) => {
    const index = options.findIndex((o) => o.label === e.target.value);
    // Формируем массив опций с параметрами
    const newOptions = options.map((item, i) =>
      i === index ? { ...item, checked: e.target.checked } : item
    );
    // Получаем количество чекнутых
    const checkedOptions = newOptions.filter((o) => o.checked);
    // Из чекнутых выпиливаем те что с пустым value
    const filledOptions = checkedOptions.filter((o) => o.value !== undefined);
    // Проверяем чтобы чекнутых было достаточно
    if (checkedOptions.length > 0) {
      // Получаем параметры текущего чекнутого
      const current = newOptions.find((_, i) => i === index);
      // Если комментарий не требуется, записываем ответ сразу
      if (!current.require_comment) {
        dispatch(
          updateQuestion({
            ...question,
            parameters: {
              ...question.parameters,
              options: newOptions,
            },
            answer: JSON.stringify(checkedOptions),
          })
        );
      } else {
        dispatch(
          updateQuestion({
            ...question,
            parameters: {
              ...question.parameters,
              options: newOptions,
            },
            answer: filledOptions.length > 0 ? JSON.stringify(filledOptions) : undefined,
          })
        );
      }
    } else {
      dispatch(
        updateQuestion({
          ...question,
          parameters: {
            ...question.parameters,
            options: newOptions,
          },
          answer: undefined,
        })
      );
    }
  };

  const handleComment = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const newOptions = options.map((item, key) =>
      key === index ? { ...item, value: e.target.value } : item
    );
    // Получаем количество чекнутых
    const checkedOptions = newOptions.filter((o) => o.checked);
    // Из чекнутых выпиливаем те что с пустым value
    const filledOptions = checkedOptions.filter((o) => o.value === undefined);
    // Если поле не пустое
    if (e.target.value) {
      dispatch(
        updateQuestion({
          ...question,
          parameters: {
            ...question.parameters,
            options: options.map((item, key) =>
              key === index ? { ...item, value: e.target.value } : item
            ),
          },
          answer: JSON.stringify(checkedOptions),
        })
      );
    } else {
      dispatch(
        updateQuestion({
          ...question,
          parameters: {
            ...question.parameters,
            options: options.map((item, key) =>
              key === index ? { ...item, value: undefined } : item
            ),
          },
          answer: filledOptions.length > 0 ? JSON.stringify(filledOptions) : undefined,
        })
      );
    }
  };

  return (
    <QuestionWrapper>
      <QuestionTitle>{question.text}</QuestionTitle>
      <QuestionBody>
        {options.map((option: QuestionOptionsRadioParameter, key: number) => (
          <Checkbox
            key={key}
            checked={option.checked}
            value={option.label}
            onChange={handleChanges}
          >
            <Space>
              <span>{option.label}</span>
              {option.require_comment && option.checked && (
                <Input
                  value={option.value}
                  onChange={(e) => handleComment(e, key)}
                  placeholder={t("templates.comment")}
                />
              )}
            </Space>
          </Checkbox>
        ))}
      </QuestionBody>
    </QuestionWrapper>
  );
};

export default MultipleType;
