import React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { useSelector } from "react-redux";

import { RootState } from "../store";

const ProtectedRoute: React.FC<RouteProps> = ({ component: Component, ...rest }) => {
  const { filled_survey_id, uuid } = useSelector((state: RootState) => state.app);
  // Проверяем наличие компонента
  if (!Component) return null;
  // Проверяем id анкеты и uuid
  if (!filled_survey_id || !uuid) return <Redirect to="/" />;
  // Выводим роут
  return (
    <Route
      {...rest}
      render={(props) => {
        return <Component {...rest} {...props} />;
      }}
    />
  );
};
export default ProtectedRoute;
