import React, { useCallback, useEffect, useMemo } from "react";
import { Slider } from "antd";
import { useDispatch } from "react-redux";

import { QTypeProps } from "./QTypeProps";
import { updateQuestion } from "../../store/questions/actions";
import { marksSlider } from "../../helpers/marksSlider";
import {
  QuestionBody,
  QuestionBoldText,
  QuestionHint,
  QuestionTitle,
  QuestionWrapper,
} from "./questions.styled";

const ScaleType: React.FC<QTypeProps> = ({ question }) => {
  const dispatch = useDispatch();

  const updateValue = useCallback(
    (value: number) => dispatch(updateQuestion({ ...question, answer: value })),
    [dispatch, question]
  );

  const answerLabel = useMemo(() => {
    switch (question.answer) {
      case -2:
        return "Абсолютно не согласен";
      case -1:
        return "Скорее не согласен";
      case 0:
        return "И согласен, и не согласен";
      case 1:
        return "Скорее согласен";
      case 2:
        return "Абсолютно согласен";
    }
  }, [question.answer]);

  const handleKeyDown = useCallback(
    (ev) => {
      let value;
      switch (ev.code) {
        case "Digit1":
          value = -2;
          break;
        case "Digit2":
          value = -1;
          break;
        case "Digit3":
          value = 0;
          break;
        case "Digit4":
          value = 1;
          break;
        case "Digit5":
          value = 2;
          break;
        default:
          break;
      }
      typeof value === "number" && updateValue(value);
    },
    [updateValue]
  );

  // Ловим ответы с клавиатуры
  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <QuestionWrapper>
      <QuestionTitle>{question.text}</QuestionTitle>
      <QuestionHint>
        Выберите один из вариантов ответа, передвинув бегунок в нужную позицию. Или используйте
        горячие клавиши от 1 до 5.
      </QuestionHint>
      <QuestionBody>
        <QuestionBoldText>Ваш ответ:</QuestionBoldText>
        <Slider
          key={question.id}
          value={question.answer ? +question.answer : 0}
          onChange={updateValue}
          min={-2}
          max={2}
          step={1}
          dots
          included
          tooltipVisible={false}
          marks={marksSlider(false)}
          //reverse={question.parameters.reverse_scale}
        />
        <QuestionBoldText color="#200E32">{answerLabel}</QuestionBoldText>
      </QuestionBody>
    </QuestionWrapper>
  );
};

export default ScaleType;
