import {
  DEPARTMENT_ADD,
  DEPARTMENT_DELETE,
  DEPARTMENT_UPDATE,
  DEPARTMENT_SET,
  IDepartment,
  DepartmentActionTypes,
} from "./types";

const initialState = [] as IDepartment[];

export function departmentsReducer(
  state = initialState,
  action: DepartmentActionTypes
): IDepartment[] {
  switch (action.type) {
    case DEPARTMENT_ADD:
      return [...state, { ...action.payload }];
    case DEPARTMENT_DELETE:
      return state.filter((item) => item.id !== action.payload);
    case DEPARTMENT_UPDATE:
      return state.map((item) => (item.id === action.payload.id ? action.payload : item));
    case DEPARTMENT_SET:
      return action.payload;
    default:
      return state;
  }
}
