import React from "react";
import { useDispatch } from "react-redux";
import { useDebounceEffect } from "ahooks";
import { updateQuestion } from "../../store/questions/actions";
import { QTypeProps } from "./QTypeProps";

const HtmlType: React.FC<QTypeProps> = ({ question }) => {
  const dispatch = useDispatch();
  const html: string = question.parameters.html || "";

  /* *
   * Устанавливаем пустой дефолтный ответ на вопрос для типа html
   */
  useDebounceEffect(() => {
    !question.answer && dispatch(updateQuestion({ ...question, answer: "" }));
  }, [question.answer]);

  return (
    <div className="question">
      <div
        className="question__body"
        style={{ padding: 20, fontSize: "1.1rem" }}
        dangerouslySetInnerHTML={{ __html: html }}
      />
    </div>
  );
};

export default React.memo(HtmlType);
