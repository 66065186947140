import styled from "styled-components";
import { device } from "../helpers/mediaQueries";

export const SurveyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  max-width: 600px;
  margin: 0 auto;
`;

export const SurveyFooter = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 2rem 10px;
  button {
    font-weight: normal;
    font-size: 14px;
    @media ${device.tablet} {
      font-weight: bold;
      font-size: 16px;
    }
  }
`;

export const SurveyProgress = styled.div<{ percent: number }>`
  background: #ededed;
  height: 6px;
  flex-basis: 100%;
  margin-bottom: 16px;
  border-radius: 6px;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background-color: #200e32;
    width: ${({ percent }) => percent}%;
    height: 100%;
    border-radius: 6px;
  }
`;

export const SurveyInfo = styled.div`
  text-align: center;
  color: #72677d;
  font-size: 14px;
  line-height: 17px;
`;
