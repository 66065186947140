import { Result } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const NoFound = () => {
  const { t } = useTranslation();

  return <Result status="404" title={t("404.title")} />;
};

export default NoFound;
