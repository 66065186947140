export const SET_PAGE = "APP/SET_PAGE";
export const SET_LOADING = "APP/SET_LOADING";
export const SET_UUID = "APP/SET_UUID";
export const SET_FILLED_SURVEY_ID = "APP/SET_FILLED_SURVEY_ID";
export const SET_REDIRECT = "APP/SET_REDIRECT";
export const SET_COMPANY_ID = "APP/SET_COMPANY_ID";
export const SET_DEPARTMENT_ID = "APP/SET_DEPARTMENT_ID";

interface IRedirectSetAction {
  type: typeof SET_REDIRECT;
  payload: string | undefined;
}

interface IDepartmentIdSetAction {
  type: typeof SET_DEPARTMENT_ID;
  payload?: number;
}

interface ICompanyIdSetAction {
  type: typeof SET_COMPANY_ID;
  payload: number;
}

interface IPageSetAction {
  type: typeof SET_PAGE;
  payload: number;
}

interface ILoadingSetAction {
  type: typeof SET_LOADING;
  payload: boolean;
}

interface IUUIDSetAction {
  type: typeof SET_UUID;
  payload: string;
}

interface IFilledSurveyIdSetAction {
  type: typeof SET_FILLED_SURVEY_ID;
  payload: string;
}

export type AppActionTypes =
  | IRedirectSetAction
  | IDepartmentIdSetAction
  | ICompanyIdSetAction
  | IPageSetAction
  | ILoadingSetAction
  | IUUIDSetAction
  | IFilledSurveyIdSetAction;

export interface AppState {
  isLoading: boolean;
  uuid: string;
  filled_survey_id?: string;
  company_id?: number;
  department_id?: number;
  redirect: string | undefined;
}
