import axios from "axios";
import { IDepartment } from "../store/departments/types";

export const asyncLoadDepartments = async (
  survey_id: string
): Promise<IDepartment[] | undefined> => {
  try {
    const response = await axios(`/departments?survey_id=${survey_id}`);
    return response.data;
  } catch (error) {}
};
