import { Col, Layout, Result, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const Thanks = () => {
  const { t } = useTranslation();

  return (
    <Layout className="intro-page">
      <Row justify="center" align="middle">
        <Col>
          <Result
            status="success"
            title={t("thanks.title")}
            subTitle={t("thanks.subtitle")}
          />
        </Col>
      </Row>
    </Layout>
  );
};

export default Thanks;
