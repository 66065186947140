import { IQuestion, QuestionActionTypes, Q_ADD, Q_DELETE, Q_SET, Q_UPDATE } from "./types";

const initialState = [] as IQuestion[];

export function questionReducer(state = initialState, action: QuestionActionTypes): IQuestion[] {
  switch (action.type) {
    case Q_ADD:
      return [...state, { ...action.payload }];
    case Q_DELETE:
      return state.filter((q) => q.id !== action.payload);
    case Q_UPDATE:
      return state.map((q) => (q.id === action.payload.id ? action.payload : q));
    case Q_SET:
      return action.payload;
    default:
      return state;
  }
}
