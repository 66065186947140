export interface IDepartment {
  id: number;
  parent_id: number | null;
  name: string;
  employee_count: number;
}

export const DEPARTMENT_ADD = "DEPARTMENT/ADD";
export const DEPARTMENT_DELETE = "DEPARTMENT/DELETE";
export const DEPARTMENT_UPDATE = "DEPARTMENT/UPDATE";
export const DEPARTMENT_SET = "DEPARTMENT/SET";

interface IDepartmentetAction {
  type: typeof DEPARTMENT_SET;
  payload: IDepartment[];
}

interface IDepartmentDeleteAction {
  type: typeof DEPARTMENT_DELETE;
  payload: number;
}

interface IDepartmentUpdateAction {
  type: typeof DEPARTMENT_UPDATE;
  payload: IDepartment;
}

interface IDepartmentAddAction {
  type: typeof DEPARTMENT_ADD;
  payload: IDepartment;
}

export type DepartmentActionTypes =
  | IDepartmentAddAction
  | IDepartmentUpdateAction
  | IDepartmentDeleteAction
  | IDepartmentetAction;
