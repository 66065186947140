export type FormatAnswerType = {
  [key: string]: number;
};

export type AnswerType = {
  key: string;
  score: number;
  text: string;
};
export type QuestionOptionsRadioParameter = {
  label: string;
  value?: string;
  require_comment?: boolean;
  checked?: boolean;
};

export type QuestionOptionsScaleParameter = {
  text: string;
  key: string;
  value?: number;
};

export interface IAnswer {
  question_id: number;
  value: any;
}

export interface IQuestion {
  id: number;
  type: string;
  text: string;
  order_number: number;
  survey_id?: number;
  indicator_id?: number;
  socdem_id?: number;
  answer?: string | number | FormatAnswerType;
  parameters: QuestionParameters;
}
export const Q_ADD = "QUESTION/ADD";
export const Q_DELETE = "QUESTION/DELETE";
export const Q_UPDATE = "QUESTION/UPDATE";
export const Q_SET = "QUESTION/SET";
export const Q_ANSWER = "QUESTION/ANSWER";

interface ISetAnswerAction {
  type: typeof Q_ANSWER;
  payload: string | number | undefined;
}

interface ISetQuestionAction {
  type: typeof Q_SET;
  payload: IQuestion[];
}

interface IRemoveQuestionAction {
  type: typeof Q_DELETE;
  payload: number;
}

interface IUpdateQuestionAction {
  type: typeof Q_UPDATE;
  payload: IQuestion;
}

interface IAddQuestionAction {
  type: typeof Q_ADD;
  payload: IQuestion;
}

export type QuestionActionTypes =
  | ISetAnswerAction
  | IRemoveQuestionAction
  | IUpdateQuestionAction
  | IAddQuestionAction
  | ISetQuestionAction;

export type QuestionParameters = {
  validation?: string;
  min?: number;
  max?: number;
  hint?: string;
  score?: number;
  html?: string;
  options?: QuestionOptionsRadioParameter[] | QuestionOptionsScaleParameter[];
  indicator_id?: number;
  reverse_scale?: boolean;
};
