import {
  AppState,
  AppActionTypes,
  SET_FILLED_SURVEY_ID,
  SET_LOADING,
  SET_UUID,
  SET_COMPANY_ID,
  SET_REDIRECT,
  SET_DEPARTMENT_ID,
} from "./types";

const url = new URL(document.location.toString());
const uuid = localStorage.getItem("uuid");
const department_id = localStorage.getItem("department_id");
const filled_survey_id = localStorage.getItem("filled_survey_id");

const initialState: AppState = {
  isLoading: false,
  filled_survey_id: filled_survey_id || "",
  uuid: url.searchParams.get("uuid") || uuid || "",
  department_id: department_id ? +department_id : undefined,
  redirect: undefined,
};
export function appReducer(state = initialState, action: AppActionTypes): AppState {
  switch (action.type) {
    case SET_REDIRECT:
      return { ...state, redirect: action.payload };
    case SET_LOADING:
      return { ...state, isLoading: action.payload };
    case SET_UUID:
      return { ...state, uuid: action.payload };
    case SET_FILLED_SURVEY_ID:
      return { ...state, filled_survey_id: action.payload };
    case SET_COMPANY_ID:
      return { ...state, company_id: action.payload };
    case SET_DEPARTMENT_ID:
      return { ...state, department_id: action.payload };
    default:
      return state;
  }
}
