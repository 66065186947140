import styled from "styled-components";
import lamp from "../../assets/lamp.svg";
import { device } from "../../helpers/mediaQueries";
export const QuestionWrapper = styled.div`
  margin: 10px;
  @media ${device.tablet} {
    margin: 0;
  }
`;

export const QuestionTitle = styled.div`
  color: #200e32;
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  margin: 30px 0;
`;

export const QuestionBody = styled.div`
  display: flex;
  flex-direction: column;
  .ant-slider:hover {
    .ant-slider-track {
      background: transparent;
    }
  }
  .ant-slider-track {
    background: transparent;
  }
  .ant-checkbox-wrapper {
    width: 100%;

    &.ant-checkbox-wrapper-checked {
      border: 1px solid #200e32 !important;
      span {
        color: #200e32 !important;
      }
    }
    border: 1px solid #e0dbd1;
    border-radius: 8px;
    margin-bottom: 6px;
    padding: 10px 16px;
    span {
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: #72677d;
    }
  }
  .ant-radio-group {
    display: flex;
    flex-direction: column;
    .ant-radio-button-wrapper {
      &::before {
        display: none;
      }
      &.ant-radio-button-wrapper-checked {
        background: transparent;
        border: 1px solid #200e32 !important;
        span {
          color: #200e32 !important;
        }
      }
      border: 1px solid #e0dbd1;
      border-radius: 8px;
      margin-bottom: 6px;
      height: 52px;
      padding: 9px 16px;
      span {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: #72677d;
      }
    }
  }
`;

export const QuestionHint = styled.div`
  background: #faf9f7;
  border-radius: 12px;
  background-image: url(${lamp});
  background-repeat: no-repeat;
  background-position: 15px center;
  padding: 15px 15px 15px 50px;
  margin-bottom: 30px;
`;

export const QuestionBoldText = styled.div<{ color?: string }>`
  color: ${({ color }) => color || "#72677D"};
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
`;
