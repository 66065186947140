import { AppThunk } from "..";
import { asyncLoadDepartments } from "../../services/departments.services";
import {
  DEPARTMENT_ADD,
  DEPARTMENT_DELETE,
  DEPARTMENT_UPDATE,
  DEPARTMENT_SET,
  IDepartment,
  DepartmentActionTypes,
} from "./types";

export function deleteDepartment(id: number): DepartmentActionTypes {
  return {
    type: DEPARTMENT_DELETE,
    payload: id,
  };
}

export function addDepartment(department: IDepartment): DepartmentActionTypes {
  return {
    type: DEPARTMENT_ADD,
    payload: department,
  };
}

export function updateDepartment(department: IDepartment): DepartmentActionTypes {
  return {
    type: DEPARTMENT_UPDATE,
    payload: department,
  };
}

export function setDepartments(departments: IDepartment[]): DepartmentActionTypes {
  return {
    type: DEPARTMENT_SET,
    payload: departments,
  };
}

export const loadDepartments =
  (survey_id: string): AppThunk =>
  async (dispatch) => {
    try {
      const departments = await asyncLoadDepartments(survey_id);
      departments && dispatch(setDepartments(departments));
    } catch (error) {
      console.log(error.response.status);
    }
  };
