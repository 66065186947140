import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Layout, Spin } from "antd";
import { useSelector } from "react-redux";

import NoFound from "./pages/NoFound";
import Start from "./pages/Start";
import Thanks from "./pages/Thanks";
import Survey from "./pages/Survey";
import ProtectedRoute from "./components/ProtectedRoute";

import { RootState } from "./store";

const { Content } = Layout;

function App() {
  const { isLoading } = useSelector((state: RootState) => state.app);

  return (
    <Spin tip="Загрузка..." spinning={isLoading}>
      <Layout hasSider={false}>
        <Router>
          <Content className="content">
            <Switch>
              <Route exact path="/" component={Start} />
              <Route exact path="/thanks" component={Thanks} />
              <ProtectedRoute exact path="/step/:page" component={Survey} />
              <Route path="*" component={NoFound} />
            </Switch>
          </Content>
        </Router>
      </Layout>
    </Spin>
  );
}

export default App;
