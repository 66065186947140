import React from "react";

import { IQuestion } from "../store/questions/types";

import CommentType from "./templates/CommentType";
import DistributionType from "./templates/DistributionType";
import HtmlType from "./templates/HtmlType";
import MultipleType from "./templates/MultipleType";
import RadioType from "./templates/RadioType";
import ScaleType from "./templates/ScaleType";
import TextType from "./templates/TextType";

const Question: React.FC<{ question: IQuestion }> = ({ question }) => {
  // Отображаем вопрос в зависимости от типа
  switch (question.type) {
    case "text":
      return <TextType question={question} />;
    case "scale":
      return <ScaleType question={question} />;
    case "radio":
      return <RadioType question={question} />;
    case "multiple":
      return <MultipleType question={question} />;
    case "html":
      return <HtmlType question={question} />;
    case "distribution":
      return <DistributionType question={question} />;
    case "comment":
      return <CommentType question={question} />;
    default:
      return <></>;
  }
};

export default Question;
