export interface ISurvey {
  id: string;
  name: string;
  company_id: number;
  employee_count: number;
  started_count: number;
  finished_count: number;
  start_date: string;
  finish_date: string;
  status: string;
  is_template: boolean;
  is_archived: boolean;
}

export interface IFilledSurvey {
  id: string;
  survey_id: string;
  department_id: number;
  status: string;
}

export const SURVEY_ADD = "SURVEY/ADD";
export const SURVEY_DELETE = "SURVEY/DELETE";
export const SURVEY_UPDATE = "SURVEY/UPDATE";
export const SURVEY_SET = "SURVEY/SET";

interface ISurveySetAction {
  type: typeof SURVEY_SET;
  payload: ISurvey[];
}

interface ISurveyDeleteAction {
  type: typeof SURVEY_DELETE;
  payload: string;
}

interface ISurveyUpdateAction {
  type: typeof SURVEY_UPDATE;
  payload: ISurvey;
}

interface ISurveyAddAction {
  type: typeof SURVEY_ADD;
  payload: ISurvey;
}

export type SurveyActionTypes =
  | ISurveyAddAction
  | ISurveyUpdateAction
  | ISurveyDeleteAction
  | ISurveySetAction;
