import { AppThunk } from "..";
import { asyncLoadQuestions } from "../../services/questions.services";
import { setRedirect, setUuid } from "../app/actions";
import {
  IQuestion,
  QuestionActionTypes,
  Q_ADD,
  Q_ANSWER,
  Q_DELETE,
  Q_SET,
  Q_UPDATE,
} from "./types";

export function removeQuestion(id: number): QuestionActionTypes {
  return {
    type: Q_DELETE,
    payload: id,
  };
}

export function addQuestion(question: IQuestion): QuestionActionTypes {
  return {
    type: Q_ADD,
    payload: question,
  };
}

export function updateQuestion(question: IQuestion): QuestionActionTypes {
  return {
    type: Q_UPDATE,
    payload: question,
  };
}

export function setQuestions(questions: IQuestion[]): QuestionActionTypes {
  return {
    type: Q_SET,
    payload: questions,
  };
}

export function setAnswer(value: string | number | undefined): QuestionActionTypes {
  return {
    type: Q_ANSWER,
    payload: value,
  };
}

export const loadQuestions =
  (uuid: string): AppThunk =>
  async (dispatch) => {
    try {
      // Проверяем есть ли в хранилище вопросы
      const questions = localStorage.getItem("questions");
      if (questions) {
        const answers: IQuestion[] = JSON.parse(questions);
        dispatch(setQuestions(answers));
        // получаем последнюю страницу
        const lastPage = answers.findIndex((a) => a.answer === undefined);
        if (lastPage > 0) {
          dispatch(setRedirect(`/step/${lastPage}`));
        } else {
          dispatch(setRedirect(`/step/0`));
        }
      } else {
        const questions = await asyncLoadQuestions(uuid);
        if (questions) {
          dispatch(setQuestions(questions));
          dispatch(setRedirect(`/step/0`));
        }
      }
    } catch (error) {
      dispatch(setUuid(""));
    }
  };
