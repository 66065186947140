import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Form, Input, Switch } from "antd";

import { QTypeProps } from "./QTypeProps";
import { updateQuestion } from "../../store/questions/actions";
import { useTranslation } from "react-i18next";
import { QuestionBody, QuestionTitle, QuestionWrapper } from "./questions.styled";

const CommentType: React.FC<QTypeProps> = ({ question }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [isUncommentable, setIsUncommentable] = useState(false);

  const handleChange = (changedValues: any) => {
    const { text } = changedValues;
    if (text !== "") {
      dispatch(updateQuestion({ ...question, answer: text }));
    } else {
      dispatch(updateQuestion({ ...question, answer: undefined }));
    }
  };

  const toggleComment = () => {
    if (!isUncommentable) {
      handleChange({ text: "Без комментариев" });
      setIsUncommentable(true);
    } else {
      handleChange({ text: "" });
      setIsUncommentable(false);
    }
  };

  return (
    <QuestionWrapper>
      <QuestionTitle>{question.text}</QuestionTitle>
      <QuestionBody>
        <Form form={form} onValuesChange={handleChange} layout="vertical">
          <Form.Item name="text" label={t("templates.comment")}>
            <Input.TextArea
              disabled={isUncommentable}
              autoSize={{ minRows: 3, maxRows: 23 }}
              placeholder={t("templates.enter-comment")}
            />
          </Form.Item>
          <Form.Item label={t("templates.no-comments")}>
            <Switch checked={isUncommentable} onChange={toggleComment} />
          </Form.Item>
        </Form>
      </QuestionBody>
    </QuestionWrapper>
  );
};

export default CommentType;
