import { IDepartment } from "../store/departments/types";

/**
 * Функция преобразует список департаментов в дерево
 * @param items список департаментов
 * @param id родительский ID
 */
export const treeRecursive = (items: IDepartment[], id: number | null = null): object[] => {
  return items
    .filter((item) => item.parent_id === id)
    .map((item) => ({
      ...item,
      key: item.id,
      title: item.name,
      value: item.id,
      children: treeRecursive(items, item.id),
    }));
};
