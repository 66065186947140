import { AppThunk } from "..";
import { asyncLoadSurvey } from "../../services/surveys.services";
import { loadDepartments } from "../departments/actions";
import { loadQuestions } from "../questions/actions";
import {
  AppActionTypes,
  SET_LOADING,
  SET_FILLED_SURVEY_ID,
  SET_UUID,
  SET_COMPANY_ID,
  SET_REDIRECT,
  SET_DEPARTMENT_ID,
} from "./types";

export function setDepartmentId(department_id?: number): AppActionTypes {
  return {
    type: SET_DEPARTMENT_ID,
    payload: department_id,
  };
}

export function setCompanyId(company_id: number): AppActionTypes {
  return {
    type: SET_COMPANY_ID,
    payload: company_id,
  };
}

export function setLoading(status: boolean): AppActionTypes {
  return {
    type: SET_LOADING,
    payload: status,
  };
}

export function setFilledSurveyId(filled_survey_id: string): AppActionTypes {
  return {
    type: SET_FILLED_SURVEY_ID,
    payload: filled_survey_id,
  };
}

export function setUuid(uuid: string): AppActionTypes {
  return {
    type: SET_UUID,
    payload: uuid,
  };
}

export function setRedirect(link?: string): AppActionTypes {
  return {
    type: SET_REDIRECT,
    payload: link,
  };
}

export const loadSurveyData =
  (uuid: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setLoading(true));
      // Загружаем данные об исследовании
      const survey = await asyncLoadSurvey(uuid);
      if (survey) {
        // Устанавливаем ID компании
        dispatch(setCompanyId(survey.company_id));
        // Загружаем департаменты компании по ID
        dispatch(loadDepartments(survey.id));
        // Загружаем вопросы
        dispatch(loadQuestions(survey.id));
      }
    } catch (error) {
      dispatch(setUuid(""));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const checkUuid =
  (uuid: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const survey = await asyncLoadSurvey(uuid);
      if (survey) {
        localStorage.setItem("uuid", uuid);
        dispatch(setCompanyId(survey.company_id));
        dispatch(loadDepartments(survey.id));
      }
    } catch (error) {
      dispatch(setUuid(""));
    } finally {
      dispatch(setLoading(false));
    }
  };
