import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import axios from "axios";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import App from "./App";

import { store } from "./store";
import i18next from "./i18n";

import "./sass/index.sass";

// Указываем дефолтный урл для запросов
axios.defaults.baseURL = process.env.REACT_APP_BACKEND!;

// Отчищаем все localStorage старых опросов
// В дальнейшем можно удалить, так как отчищение реализовано после завершения опроса
const startedTime = localStorage.getItem("started_time");
if (!startedTime) {
  localStorage.removeItem("questions");
  localStorage.removeItem("filled_survey_id");
  localStorage.removeItem("department_id");
}

// Перехватываем запросы и добавляем в заголовок выбранный язык
axios.interceptors.request.use(function (config) {
  const { language } = i18next;
  config.headers!["Accept-Language"] = language;
  return config;
});

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
