import { Action, combineReducers, createStore, applyMiddleware } from "redux";
import thunk, { ThunkAction } from "redux-thunk";

import { appReducer } from "./app/reducer";
import { surveysReducer } from "./surveys/reducer";
import { questionReducer } from "./questions/reducer";
import { departmentsReducer } from "./departments/reducer";

export type RootState = ReturnType<typeof rootReducer>;
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;

const rootReducer = combineReducers({
  questions: questionReducer,
  departments: departmentsReducer,
  surveys: surveysReducer,
  app: appReducer,
});

const store = createStore<RootState, any, any, any>(rootReducer, applyMiddleware(thunk));

export { store };
