import React, { useState } from "react";
import { Input, Radio } from "antd";
import { RadioChangeEvent } from "antd/lib/radio/interface";
import { useDispatch } from "react-redux";

import { QTypeProps } from "./QTypeProps";
import { updateQuestion } from "../../store/questions/actions";
import { QuestionOptionsRadioParameter } from "../../store/questions/types";
import { useTranslation } from "react-i18next";
import { QuestionBody, QuestionTitle, QuestionWrapper } from "./questions.styled";

const RadioType: React.FC<QTypeProps> = ({ question }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isRequiredComment, setIsRequiredComment] = useState(false);

  const handleAnswer = (event: RadioChangeEvent) => {
    const { value } = event.target;
    if (!value.require_comment) {
      setIsRequiredComment(false);
      dispatch(updateQuestion({ ...question, answer: value.value }));
    } else {
      dispatch(updateQuestion({ ...question, answer: undefined }));
      setIsRequiredComment(true);
    }
  };

  const handleComment = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value !== "") {
      dispatch(updateQuestion({ ...question, answer: event.target.value }));
    } else {
      dispatch(updateQuestion({ ...question, answer: undefined }));
    }
  };

  const options: any = question.parameters?.options || [];

  return (
    <QuestionWrapper>
      <QuestionTitle>{question.text}</QuestionTitle>
      <QuestionBody>
        <Radio.Group
          buttonStyle="solid"
          onChange={handleAnswer}
          value={options.find((option: any) => option.value === question.answer)}
        >
          {options.map((option: QuestionOptionsRadioParameter, key: number) => (
            <Radio.Button key={key} value={option}>
              {option.label}
            </Radio.Button>
          ))}
        </Radio.Group>
        {isRequiredComment && (
          <Input
            required
            placeholder={t("templates.comment")}
            onChange={handleComment}
            style={{ marginTop: "1rem" }}
          />
        )}
      </QuestionBody>
    </QuestionWrapper>
  );
};

export default RadioType;
