import axios from "axios";
import { IAnswer } from "../store/questions/types";
import { IFilledSurvey, ISurvey } from "../store/surveys/types";

export const asyncLoadSurvey = async (uuid: string): Promise<ISurvey | undefined> => {
  try {
    const response = await axios(`/surveys/${uuid}`);
    return response.data;
  } catch (error) {}
};

export const asyncLoadFilledSurvey = async (
  filled_survey_id: string
): Promise<IFilledSurvey | undefined> => {
  try {
    const response = await axios(`/filled_survey/${filled_survey_id}`);
    return response.data;
  } catch (error) {}
};

export const asyncCreateFilledSurvey = async (
  survey_id: string,
  department_id: number
): Promise<IFilledSurvey | undefined> => {
  try {
    const response = await axios.post(`/filled_survey`, { survey_id, department_id });
    return response.data;
  } catch (error) {}
};

export const asyncSendAnswers = async (
  uuid: string,
  filled_survey_id: string,
  answers: IAnswer[]
): Promise<boolean> => {
  try {
    const response = await axios.post(`/surveys/${uuid}/answers`, { filled_survey_id, answers });
    return response.status === 201;
  } catch (error) {
    return false;
  }
};
