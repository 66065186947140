import { Button } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { RootState } from "../store";
import { setRedirect } from "../store/app/actions";
import { loadQuestions } from "../store/questions/actions";
import { asyncSendAnswers } from "../services/surveys.services";
import { SurveyFooter, SurveyInfo, SurveyProgress, SurveyWrapper } from "../shared/survey.styled";
import Question from "../components/Question";

const Survey = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { page } = useParams<{ page: string }>();
  const { questions, app } = useSelector((state: RootState) => state);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Считаем количество отвеченных вопросов
  const answeredCount = questions.filter((question) => question.answer !== undefined).length;
  // Считаем прогресс
  const progress = Math.round((answeredCount / questions.length) * 100);
  // Проверяем является ли текущая страница последняя
  const isLastPage = questions.length === +page + 1;
  // Статус ответа
  const isAnswered = questions[+page]?.answer !== undefined;

  /**
   * Отправляем ответы на сервер
   */
  const handleSubmit = useCallback(async () => {
    if (!app.filled_survey_id || !app.uuid) return;
    // Включаем отправку
    setIsSubmitting(true);
    const answers = questions.map((answer) => {
      return {
        question_id: answer.id,
        value: answer.answer,
      };
    });
    try {
      const isSent = await asyncSendAnswers(app.uuid, app.filled_survey_id, answers);
      if (isSent) {
        localStorage.removeItem("questions");
        localStorage.removeItem("filled_survey_id");
        localStorage.removeItem("department_id");
        localStorage.removeItem("started_time");
        history.push("/thanks");
      }
    } catch (error) {
    } finally {
      setIsSubmitting(false);
    }
  }, [app.filled_survey_id, app.uuid, history, questions]);

  // Перехватываем Enter и Backspace
  const handleKeyDown = useCallback(
    (ev) => {
      if (ev.code === "Enter" && isAnswered) {
        if (isLastPage) {
          handleSubmit();
        } else {
          history.push(`/step/${+page + 1}`);
        }
      }
      if (ev.code === "Backspace" && +page > 0) {
        history.push(`/step/${+page - 1}`);
      }
    },
    [isAnswered, page, isLastPage, handleSubmit, history]
  );
  // Ловим ответы с клавиатуры
  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  // Записываем прогресс в локальное хранилище
  useEffect(() => {
    questions.length > 0 && localStorage.setItem("questions", JSON.stringify(questions));
  }, [questions]);

  useEffect(() => {
    app.uuid && questions.length === 0 && dispatch(loadQuestions(app.uuid));
  }, [dispatch, app.uuid, questions.length]);

  // Проверяем чтобы ответы на предыдущих страницах были заполненные
  useEffect(() => {
    if (questions.length > 0 && +page > 1 && answeredCount === 0) {
      history.push("/step/1");
    }
  }, [page, history, answeredCount, questions.length]);

  // Если нужно переместиться на другую страницу
  useEffect(() => {
    app.redirect && history.push(app.redirect);
    return () => {
      dispatch(setRedirect());
    };
  }, [dispatch, history, app.redirect]);

  if (questions.length === 0) return <></>;

  return (
    <SurveyWrapper>
      {questions
        .sort((a, b) => a.order_number - b.order_number)
        .slice(+page, +page + 1)
        .map((question, key) => (
          <Question key={key} question={question} />
        ))}
      <SurveyFooter>
        <SurveyProgress percent={progress} />
        <Button disabled={+page === 0} onClick={() => history.push(`/step/${+page - 1}`)}>
          Предыдущий
        </Button>
        <SurveyInfo>
          Вопрос {+page + 1} из {questions.length}
          <br />
          <b>Заполнено на {progress}%</b>
        </SurveyInfo>
        {isLastPage ? (
          <Button loading={isSubmitting} type="primary" onClick={handleSubmit}>
            Отправить
          </Button>
        ) : (
          <Button
            type="primary"
            disabled={!isAnswered}
            onClick={() => history.push(`/step/${+page + 1}`)}
          >
            Следующий
          </Button>
        )}
      </SurveyFooter>
    </SurveyWrapper>
  );
};

export default Survey;
